<template>
  <vs-popup title="修改学员分组" :active.sync="showBox">
    <!-- list -->
    <div v-if="!showEdit" class="vx-row">
      <vs-row v-for="(g, gIdx) in groups" :key="gIdx" vs-w="12" class="cursor-pointer group-list" vs-type="flex" vs-align="center">
        <vs-col vs-w="6" @click.native="selectGroup(g)">{{g.name}}</vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
          <vs-button color="warning" type="filled" icon="delete" class="mb-2" @click="delGroup(g)"></vs-button>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12">
        <vs-col vs-w="12">
        <vs-button color="warning" type="border" class="mb-2" @click="showBox=false;">取消</vs-button>
        </vs-col>
      </vs-row>
    </div>

    <!-- edit -->
    <div v-if="showEdit">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            label="分组名称"
            v-model="group.name"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2">保存</vs-button>
          <vs-button color="warning" type="border" class="mb-2" @click="showBox=false;">取消</vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  props: ["groups", "show"],

  data() {
    return {
      showBox: this.show,
      showEdit: false,

      group: {
        name: ""
      }
    };
  },

  watch: {
    show: function(newVal, oldVal) {
      if (newVal != oldVal) this.showBox = newVal;
    },
    showBox: function(newVal) {
      if (!newVal) {
        this.$emit("hide");
        this.showEdit = false;
      }
    }
  },

  methods: {
    selectGroup(group) {
      // console.log(group.id);
      this.group = group;
      this.showEdit = true;
    },

    delGroup(group) {
      // console.log(group.id);
      if (group){
        //TODO: dispatch("...")
      }
    }
  }
};
</script>

<style scoped>
.group-list:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
</style>
