<template>
  <vs-popup title="添加新学员分组" :active.sync="showBox">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="分组名称" v-model="group.name" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2">保存</vs-button>
        <vs-button color="warning" type="border" class="mb-2" @click="showBox=false;">取消</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  props: ["show"],

  data() {
    return {
      showBox: this.show,

      group: {
        name: '',
      }
    };
  },

  watch:{
    show: function(newVal, oldVal){
      if (newVal!=oldVal) this.showBox = newVal;
    },
    showBox: function(newVal){
      if (!newVal) this.$emit('hide');
    }
  },

  methods: {
  }
};
</script>
