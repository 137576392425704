<template>
  <vs-col id="clients-main" class="relative overflow-hidden">
    <vs-row vs-type="flex" vs-justify="space-between" vs-w="12">
      <!-- SEARCH BAR -->
      <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="8">
        <vs-input
          icon="icon-search"
          size="large"
          icon-pack="feather"
          placeholder="检索客户"
          v-model="searchQuery"
          class="vs-input-no-border vs-input-no-shdow-focus w-full no-icon-border"
        />
      </vs-col>

      <!--actions-->
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <vs-row vs-w="12" class="space">
          <NewClient
            :trainer_id="activeTrainer.id"
            :show="showNewClient"
            @hide="showNewClient=false"
            :organization="activeOrganization"
          />
          <NewGroup :show="showNewGroup" @hide="showNewGroup=false" />
          <EditGroup :groups="groups" :show="showEditGroup" @hide="showEditGroup=false" />
          <vs-button icon="person_add" @click="clientAddOpen">添加学员</vs-button>
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <vs-button icon="person_add">管理学员分组</vs-button>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="addGroups">
                <vs-icon class icon="add"></vs-icon>
                <span>新建学员分组</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="manageGroups">
                <vs-icon class icon="edit"></vs-icon>
                <span>修改已有学员分组</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </vs-row>
      </vs-col>
    </vs-row>

    <!--client list -->
    <vs-tabs>
      <vs-tab label="激活的学员" icon="person">
        <vs-col v-if="activeClients.length==0" class="con-tab-ejemplo" vs-type="flex" vs-justify="center" vs-w="12" >
          <span>没有数据</span>
        </vs-col>
        <vs-col v-else class="con-tab-ejemplo">
          <vs-row vs-type="flex" vs-justify="space-between" vs-w="12" class="list-title">
            <vs-col vs-w="2">姓名</vs-col>
            <vs-col vs-w="2">电话</vs-col>
            <vs-col vs-w="2">最近训练日</vs-col>
            <vs-col vs-w="2">训练完成率</vs-col>
            <vs-col vs-w="4">所在分组</vs-col>
          </vs-row>

          <vs-row
            v-for="(item, cIdx) in activeClients"
            :key="cIdx"
            vs-type="flex"
            vs-justify="space-between"
            vs-w="12"
            class="list-item"
          >
            <vs-col vs-w="2">
              <div
                class="flex items-center ml-1 cursor-pointer"
                @click="$router.push({path:'/clients/'+ item.client.slug +'/workouts'})"
              >
                <vs-checkbox class="vs-checkbox-small ml-0 mr-1"></vs-checkbox>
                <vs-avatar
                  :text="item.user.name"
                  :src="item.user.image_url"
                />
                <span class="client-name">{{item.user.name}}</span>
              </div>
            </vs-col>
            <vs-col vs-w="2" class="space">
              <span >{{item.user.mobile}}</span>
            </vs-col>
            <vs-col vs-w="2" class="space">
              <span v-if="item.client.due" class="due">{{localDate(item.client.due)}}</span>
            </vs-col>
            <vs-col vs-w="2">
              <span
                :class="'rate ' + complianceRateName(item.client.compliance_rate_for_7_days)"
              >{{complianceRate(item.client.compliance_rate_for_7_days)}}<sup>%</sup></span>
              <span
                :class="'rate ' + complianceRateName(item.client.compliance_rate_for_30_days)"
              >{{complianceRate(item.client.compliance_rate_for_30_days)}}<sup>%</sup></span>
              <span
                :class="'rate ' + complianceRateName(item.client.compliance_rate_for_90_days)"
              >{{complianceRate(item.client.compliance_rate_for_90_days)}}<sup>%</sup></span>
            </vs-col>
            <vs-col vs-w="4">
              <vs-chip
                v-for="(group, gIdx) in clientGroups(item.client)"
                :key="gIdx"
                class="group-name"
                closable
              >{{group.name}}</vs-chip>
              <!-- <vs-button
                v-if="clientGroups(client).length>0"
                class="group-add items-center"
                color="primary"
                type="border"
                icon="add"
              ></vs-button>
              <vs-button v-else class="items-center" color="primary" type="border" icon="add">添加到分组</vs-button> -->
            </vs-col>
            <!-- <vs-col vs-w="2" vs-type="flex" vs-justify="flex-end">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon" href="#"> 更多... <vs-icon class="" icon="expand_more"></vs-icon></a>
                <vs-dropdown-menu class="client-more">
                  <vs-dropdown-item @click="editClient(client)">
                    <vs-icon class icon="perm_identity"></vs-icon>
                    <span>修改学员信息</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="transferClient(client)">
                    <vs-icon class icon="transfer_within_a_station"></vs-icon>
                    <span>转换教练</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="archiveClient(editClient)">
                    <vs-icon class icon="work_outline"></vs-icon>
                    <span>归档学员</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col> -->
          </vs-row>
        </vs-col>
      </vs-tab>
      <vs-tab label="归档的学员" icon="person_outline">
        <vs-col v-if="archivedClients.length==0" class="con-tab-ejemplo" vs-type="flex" vs-justify="center" vs-w="12" >
          <span>没有数据</span>
        </vs-col>
        <vs-col v-else class="con-tab-ejemplo">
          <vs-row vs-type="flex" vs-justify="space-between" vs-w="12" class="list-title">
            <vs-col vs-w="2">姓名</vs-col>
            <vs-col vs-w="2">电话</vs-col>
            <vs-col vs-w="2">最近训练日</vs-col>
            <vs-col vs-w="2">训练完成率</vs-col>
            <vs-col vs-w="4">所在分组</vs-col>
          </vs-row>

          <vs-row
            v-for="(item, cIdx) in archivedClients"
            :key="cIdx"
            vs-type="flex"
            vs-justify="space-between"
            vs-w="12"
            class="list-item"
          >
            <vs-col vs-w="2">
              <div
                class="flex items-center ml-1 cursor-pointer"
                @click="$router.push({path:'/clients/'+ item.client.slug +'/workouts'})"
              >
                <vs-checkbox class="vs-checkbox-small ml-0 mr-1"></vs-checkbox>
                <vs-avatar
                  :text="item.user.name"
                  :src="item.user.image_url"
                />
                <span class="client-name">{{item.user.name}}</span>
              </div>
            </vs-col>
            <vs-col vs-w="2" class="space">
              <span >{{item.user.mobile}}</span>
            </vs-col>
            <vs-col vs-w="2" class="space">
              <span v-if="item.client.due" class="due">{{localDate(item.client.due)}}</span>
            </vs-col>
            <vs-col vs-w="2">
              <span
                :class="'rate ' + complianceRateName(item.client.compliance_rate_for_7_days)"
              >{{complianceRate(item.client.compliance_rate_for_7_days)}}<sup>%</sup></span>
              <span
                :class="'rate ' + complianceRateName(item.client.compliance_rate_for_30_days)"
              >{{complianceRate(item.client.compliance_rate_for_30_days)}}<sup>%</sup></span>
              <span
                :class="'rate ' + complianceRateName(item.client.compliance_rate_for_90_days)"
              >{{complianceRate(item.client.compliance_rate_for_90_days)}}<sup>%</sup></span>
            </vs-col>
            <vs-col vs-w="4">
              <vs-chip
                v-for="(group, gIdx) in clientGroups(item.client)"
                :key="gIdx"
                class="group-name"
                closable
              >{{group.name}}</vs-chip>
              <!-- <vs-button
                v-if="clientGroups(client).length>0"
                class="group-add items-center"
                color="primary"
                type="border"
                icon="add"
              ></vs-button>
              <vs-button v-else class="items-center" color="primary" type="border" icon="add">添加到分组</vs-button> -->
            </vs-col>
            <!-- <vs-col vs-w="2" vs-type="flex" vs-justify="flex-end">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon" href="#"> 更多... <vs-icon class="" icon="expand_more"></vs-icon></a>
                <vs-dropdown-menu class="client-more">
                  <vs-dropdown-item @click="editClient(client)">
                    <vs-icon class icon="perm_identity"></vs-icon>
                    <span>修改学员信息</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="transferClient(client)">
                    <vs-icon class icon="transfer_within_a_station"></vs-icon>
                    <span>转换教练</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="archiveClient(editClient)">
                    <vs-icon class icon="work_outline"></vs-icon>
                    <span>归档学员</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col> -->
          </vs-row>
        </vs-col>
      </vs-tab>
    </vs-tabs>

    <vs-popup title="学员基础信息" :active.sync="showClientProfile" v-if="currentClient">
      <ClientProfile :client_org="currentClient" />
    </vs-popup>
  </vs-col>
</template>

<script>
// import ChClientList from "@/views/components/coach/client-list/ClientList.vue";
import NewClient from "@/views/components/coach/client/NewClient.vue";
import NewGroup from "@/views/components/coach/client/NewGroup.vue";
import EditGroup from "@/views/components/coach/client/EditGroup.vue";
import ClientProfile from "@/views/components/coach/client/ClientProfile.vue";

// import * as sample from "@/store/samples/coach/clients/clients.js";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  data() {
    return {
      showNewClient: false,
      showNewTrainer: false,
      showNewGroup: false,
      showEditGroup: false,

      showClientProfile: false,
      currentClient: null,

      searchQuery: ""

      // organizations: [],
      // images: [],
      // trainers: [],
      // users: [],
      // group_assignments: [],
      // groups: [],
      // clients: []
    };
  },

  created() {
    // this.organizations = sample.organizations;
    // this.images = sample.images;
    // this.trainers = sample.trainers;
    // this.users = sample.users;
    // this.group_assignments = sample.group_assignments;
    // this.groups = sample.groups;
    // this.clients = sample.clients;
    this.$store.commit("coachPageIdx", 1);
    this.$store.dispatch("getClients", { trainer_id: this.activeTrainer.id });
  },

  computed: {
    activeClients() {
      let ary = [];
      let searchQuery = this.searchQuery.toLowerCase();

      this.clients.forEach(item => {
        if (item.trainer_id==this.activeTrainer.id && item.state == "active"){
          let user = this.getUser(item.user_id);
          if (searchQuery && (user.name.toLowerCase().indexOf(searchQuery)>=0 || user.mobile.toLowerCase().indexOf(searchQuery)>=0 ) || !searchQuery){
            let newItem = {client:item, user};
            ary.push(newItem);
          }
        }
      });
      return ary;
    },

    archivedClients() {
      let ary = [];
      let searchQuery = this.searchQuery.toLowerCase();

      this.clients.forEach(item => {
        if (item.trainer_id==this.activeTrainer.id && item.state != "active"){
          let user = this.getUser(item.user_id);
          if (searchQuery && (user.name.toLowerCase().indexOf(searchQuery)>=0 || user.mobile.toLowerCase().indexOf(searchQuery)>=0 )|| !searchQuery){
            let newItem = {client:item, user};
            ary.push(newItem);
          }
        }
      });
      return ary;
    },

    activeClientsLable() {
      return "激活的学员 (" + this.activeClients.length + ")";
    },

    archivedClientsLable() {
      return "归档的学员 (" + this.archivedClients.length + ")";
    },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeTrainer",
      "activeUser",
      "activeOrganization"
    ])
  },

  methods: {
    clientAddOpen() {
      //TODO: show new client prompt
      this.showNewClient = true;
    },

    addGroups() {
      //TODO:
      this.showNewGroup = true;
    },

    manageGroups() {
      //TODO:
      this.showEditGroup = true;
    },

    editClient(client) {
      this.currentClient = client;
      this.showClientProfile = true;
      // console.log(client.id);
    },

    transferClient(client) {
      if(client){
        //TODO:
      }
      // console.log(client.id);
    },

    archiveClient(client) {
      if(client){
        //TODO:
      }
      // console.log(client.id);
    }
  },

  components: {
    NewClient,
    NewGroup,
    EditGroup,
    ClientProfile
  }
};
</script>

<style scoped>
.space > * {
  margin-right: 1.5rem;
}

.list-title {
  background-color: #d8dcdf;
  line-height: 40px;
  border-radius: 5px 5px 0 0;
}

.list-item {
  border: none;
  padding: 5px 0;
  border-bottom: 1px solid #f4f5f6;
}

.list-item .due {
  background-color: red;
  color: white;
  line-height: 40px;
  padding: 2px 5px;
  border-radius: 4px;
}

.list-item .rate {
  padding: 0 5px;
  line-height: 40px;
}
.list-item .rate.complate {
  color: green;
}

.list-item .rate.incomplate {
  color: red;
}

.group-name {
  font-size: 1rem;
  line-height: 40px;
}

.group-add {
  height: 20px;
}

.client-more {
  min-width: 150px;
}
</style>
