<template>
  <div id="client-list-template">
    client profile
  </div>
</template>

<script>
// import axios from "axios";
// import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

// import VueSimpleSuggest from "vue-simple-suggest";
// import "vue-simple-suggest/dist/styles.css"; // Optional CSS

// import { videoPlayer } from "vue-video-player";
// import "video.js/dist/video-js.css";

// import draggable from "vuedraggable";

export default {
  mixins: [baseFun],
  props: ["client_org"],

  data() {
    return {
      //
    }
  },

  computed:{
    //
  },

  methods:{

  }
}
</script>
